import { BackgroundProcessStepStatus, buildEnumMapProvider, EnumSeverity } from 'sc-common';

export const backgroundProcessStepStatusMapProvider = buildEnumMapProvider(BackgroundProcessStepStatus, getEnumText, getEnumSeverity);

function getEnumText(value: BackgroundProcessStepStatus): string {
    switch (value) {
        case BackgroundProcessStepStatus.None:
            return $localize`None`;
        case BackgroundProcessStepStatus.Info:
            return $localize`Info`;
        case BackgroundProcessStepStatus.Warning:
            return $localize`Warning`;
        case BackgroundProcessStepStatus.Error:
            return $localize`Error`;

    }
}

function getEnumSeverity(value: BackgroundProcessStepStatus): EnumSeverity {
    switch (value) {
        case BackgroundProcessStepStatus.Info:
            return EnumSeverity.success;
        case BackgroundProcessStepStatus.Error:
            return EnumSeverity.error;
        case BackgroundProcessStepStatus.Warning:
            return EnumSeverity.warning;
        default:
            return EnumSeverity.default;
    }
}

