export class ValidatorsMessages {

    // Predefined validators
    public static readonly RequiredKey = 'required';

    public static readonly MinLengthKey = 'minlength';

    public static readonly MaxLengthKey = 'maxlength';

    public static readonly Email = 'email';

    public static readonly MinRangeKey = 'min';

    public static readonly MaxRangeKey = 'max';

    public static readonly AttachmentRequired = 'attachmentRequired';

    // Custom validators
    public static readonly NumberKey = 'number';

    public static readonly NumberPositiveNegativeKey = 'numberPositiveNegative';

    public static readonly NoWhitespaceKey = 'whitespace';

    public static readonly InvalidEmailKey = 'invalidEmail';

    public static readonly AccountNotFoundKey = 'accountNotFound';

    public static readonly InvalidPasswordKey = 'invalidPassword';

    public static readonly InvalidCurrentPasswordKey = 'invalidCurrentPassword';

    public static readonly PasswordStrengthKey = 'passwordStrength';

    public static readonly KurzelKey = 'kurzel';

    public static readonly InvalidWebsite = 'invalidWebsite';

    public static readonly ValueChangedKey = 'valueChanged';

    public static readonly TooManyItemsKey = 'tooManyItems';

    public static readonly WrongDecimalScaleKey = 'wrongDecimalScale';

    public static readonly ConfirmPasswordErrorKey = 'confirmPasswordError';

    public static readonly AlphabeticErrorKey = 'alphabeticError';

    public static readonly EmailAlreadyUsedKey = 'emailAlreadyUsed';

    public static readonly EmailAlreadyUsedWithSearchHintKey = 'emailAlreadyUsedWithSearchHintKey';

    public static readonly MemberNotFoundKey = 'memberNotFound';

    public static readonly NotApplicableEmailKey = 'notApplicableEmail';

    public static readonly UniqueValue = 'uniqueValue';

    public static readonly WrongPhoneNumberKey = 'wrongPhoneNumberKey';

    public static readonly AlphaNumSymbolErrorKey = 'alphaNumSymbolErrorKey';

    public static readonly InvalidAddress = 'invalidAddress';

    public static readonly InvalidName = 'invalidName';

    public static readonly InvalidUserName = 'invalidUserName';

    public static readonly NotOnlyNumbers = 'notOnlyNumbers';

    public static readonly NotOnlyLetters = 'notOnlyLetters';

    public static readonly InvalidIsbn = 'invalidIsbn';

    public static readonly InvalidIsbnRange = 'invalidIsbnRange';

    public static readonly InvalidIsbnRangeCount = 'invalidIsbnRangeCount';

    public static readonly InvalidVolumeRange = 'invalidVolumeRange';

    public static readonly InvalidIsbnTo = 'invalidIsbnTo';

    public static readonly InvalidIsbnChecksum = 'invalidIsbnChecksum';

    public static readonly FromIsGreaterThanTo = 'fromIsGreaterThanTo';

    public static readonly ToIsLessThanFrom = 'toIsLessThanFrom';

    public static readonly InvalidFileSize = 'invalidFileSize';

    public static readonly OrcidIdAlreadyUsedKey = 'orcidIdAlreadyUsedKey';

    public static readonly InvalidOrcidId = 'invalidOrcidId';

    public static readonly TagAlreadyUsed = 'tagAlreadyUsed';

    public static readonly ReasonAlreadyUsed = 'reasonAlreadyUsed';

    public static readonly CronExpressionNotValidKey = 'cronExpressionNotValid';

    public static readonly InvalidStartCampaignDate = 'invalidStartCampaignDate';

    public static getErrorMessage(validatorName: string, controlLabel: string, validatorValue: any): string {

        switch (validatorName) {
            case ValidatorsMessages.RequiredKey:
                return $localize`${ controlLabel } is required`;
            case ValidatorsMessages.NumberKey:
                return $localize`Numeric value only`;
            case ValidatorsMessages.NumberPositiveNegativeKey:
                return $localize`+/- Numeric value only`;
            case ValidatorsMessages.NoWhitespaceKey:
                return $localize`${ controlLabel } shouldn't contain whitespaces`;
            case ValidatorsMessages.InvalidEmailKey:
                return $localize`Email is invalid`;
            case ValidatorsMessages.AccountNotFoundKey:
                return $localize`Account not found`;
            case ValidatorsMessages.InvalidPasswordKey:
                return $localize`Password is invalid`;
            case ValidatorsMessages.InvalidCurrentPasswordKey:
                return $localize`Old Password is invalid`;
            case ValidatorsMessages.PasswordStrengthKey:
                return $localize`Password is not strong enough`;
            case ValidatorsMessages.InvalidWebsite:
                return $localize`Please, add the head to the link (Ex.: http:// or https://)`;
            case ValidatorsMessages.UniqueValue:
                return $localize`${ controlLabel } should be unique.`;
            case ValidatorsMessages.ValueChangedKey:
                return $localize`${ controlLabel } was not changed`;
            case ValidatorsMessages.ConfirmPasswordErrorKey:
                return $localize`Passwords must match`;
            case ValidatorsMessages.MinLengthKey:
                return $localize`Minimum ${ validatorValue.requiredLength } characters`;
            case ValidatorsMessages.MaxLengthKey:
                return $localize`Maximum ${ validatorValue.requiredLength } characters`;
            case ValidatorsMessages.TooManyItemsKey:
                return $localize`Maximum ${ validatorValue.maxItems } items`;
            case ValidatorsMessages.WrongDecimalScaleKey:
                return $localize`Maximum ${ validatorValue.decimalScale } decimals after point`;
            case ValidatorsMessages.Email:
                return $localize`Invalid e-mail address`;
            case ValidatorsMessages.InvalidAddress:
                return $localize`Use English transliteration, numbers or . : ’ - , "`;
            case ValidatorsMessages.InvalidName:
                return $localize`Use English transliteration or ' - .`;
            case ValidatorsMessages.InvalidUserName:
                return $localize`Use English letters, numbers and -._@+#.`;
            case ValidatorsMessages.AlphabeticErrorKey:
                return $localize`Please, use only English letters. Allowed punctuation mark '-'`;
            case ValidatorsMessages.EmailAlreadyUsedKey:
                return $localize`Email already used in another account`;
            case ValidatorsMessages.EmailAlreadyUsedWithSearchHintKey:
                return $localize`Email already used in another account. Please use the search field.`;
            case ValidatorsMessages.MemberNotFoundKey:
                return $localize`There are no users with this email`;
            case ValidatorsMessages.WrongPhoneNumberKey:
                return $localize`Phone number is invalid`;
            case ValidatorsMessages.AlphaNumSymbolErrorKey:
                return $localize`Please use English transliteration`;
            case ValidatorsMessages.NotOnlyNumbers:
                return $localize`Must include letters`;
            case ValidatorsMessages.NotOnlyLetters:
                return $localize`Must include numbers`;
            case ValidatorsMessages.InvalidIsbn:
                return $localize`ISBN isn't in correct format`;
            case ValidatorsMessages.InvalidIsbnRange:
                return $localize`ISBNs range is invalid.`;
            case ValidatorsMessages.InvalidVolumeRange:
                return $localize`Volume range is invalid.`;
            case ValidatorsMessages.InvalidIsbnTo:
                return $localize`ISBN To cannot be less than ISBN From`;
            case ValidatorsMessages.InvalidIsbnChecksum:
                return $localize`${ controlLabel } checksum is incorrect. Try to use ${ validatorValue }`;
            case ValidatorsMessages.InvalidIsbnRangeCount:
                return $localize`Max limit is 30 000 numbers.`;
            case ValidatorsMessages.FromIsGreaterThanTo:
                return $localize`From couldn't be greater than To`;
            case ValidatorsMessages.ToIsLessThanFrom:
                return $localize`To couldn't be less than From`;
            case ValidatorsMessages.MinRangeKey:
                return $localize`Minimum ${ validatorValue.min }`;
            case ValidatorsMessages.MaxRangeKey:
                return $localize`Maximum ${ validatorValue.max }`;
            case ValidatorsMessages.AttachmentRequired:
                return $localize`Attachment is required`;
            case ValidatorsMessages.OrcidIdAlreadyUsedKey:
                return $localize`ORCID iD already used in another account`;
            case ValidatorsMessages.InvalidOrcidId:
                return $localize`ORCID iD isn't in correct format`;
            case ValidatorsMessages.TagAlreadyUsed:
                return $localize`Tag already used.`;
            case ValidatorsMessages.ReasonAlreadyUsed:
                return $localize`Reason already used.`;
            case ValidatorsMessages.KurzelKey:
                return $localize`Kurzel must consist of 2 to 6 latin letters.`;
            case ValidatorsMessages.CronExpressionNotValidKey:
                return $localize`Cron expression is not valid.`;
            case ValidatorsMessages.InvalidStartCampaignDate:
                return $localize`The date and time must be at least 12 hours later than the current time.`;
            default:
                return $localize`Invalid ${ controlLabel }`;
        }
    }
}
