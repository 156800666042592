import { Injectable } from '@angular/core';

@Injectable()
export class TableLocalizingService {

    public readonly columns: Readonly<Record<string, string>> = {

        accessTo: $localize`Access To`,
        actions: $localize`Action`,
        added: $localize`Added on`,
        addedBy: $localize`Added by`,
        addressLine1: $localize`Address Line 1`,
        addressLine2: $localize`Address Line 2`,
        admins: $localize`Administrators`,
        affiliation: $localize`Affiliation`,
        agent: $localize`Agent`,
        archived: $localize`Archived`,
        assignedReviewed: $localize`Assigned/Reviewed`,
        attachment: $localize`Attachment`,
        auditPerformerFullName: $localize`Performer`,
        authorCode: $localize`Author code`,
        authorEmails: $localize`Author Emails`,
        authorFullName: $localize`Author`,
        authorOrder: $localize`Author Order`,
        authorPapers: $localize`As Author`,
        authors: $localize`Authors`,
        availableISBNs: $localize`Available ISBNs`,
        availableOnPublic: $localize`Public`,
        bounces: $localize`Bounces`,
        bicCodes: $localize`BIC Code/Heading`,
        bisacCodes: $localize`BISAC Code/Heading`,
        chapter: $localize`Chapter`,
        chapters: $localize`Chapters`,
        checkResult: $localize`Check Result`,
        checked: $localize`Check`,
        checkerAssignments: $localize`Checker Assignments`,
        checklistSubmitted: $localize`Checklist Submitted`,
        citations: $localize`Citations`,
        city: $localize`City`,
        coAuthor: $localize`Co-Author`,
        code: $localize`Code`,
        comments: $localize`Comments`,
        connectionStatus: $localize`Connection Status`,
        contactType: $localize`Contact Type`,
        contacts: $localize`Contacts`,
        contributorPos: $localize`Contributor/Pos`,
        copy: $localize`Copy`,
        copyrightAgreement: $localize`Copyright Agreement`,
        corresponding: $localize`Corresponding`,
        countOfNotes: $localize`Notes`,
        country: $localize`Country`,
        cron: $localize`Cron`,
        createdBy: $localize`Created by`,
        createdDateTime: $localize`Created on`,
        creatorFullName: $localize`Created by`,
        dateAdded: $localize`Added on`,
        dateCompleted: $localize`Completed`,
        dateOfBirth: $localize`Date Of Birth`,
        dateOfJoining: $localize`Date Of Joining`,
        datePrint: $localize`Date Print`,
        dateStarted: $localize`Started`,
        dateTime: $localize`Date`,
        dateWeb: $localize`Date Web`,
        defaultTitleContributor: $localize`Default Title Contributor`,
        delivered: $localize`Delivered`,
        department: $localize`Department`,
        description: $localize`Description`,
        details: $localize`Title Details`,
        diff: $localize`Difference`,
        displayOrder: $localize`Order Number`,
        doi: $localize`DOI`,
        doiDate: $localize`DOI Date`,
        doubleCheck: $localize`Double Check`,
        duration: $localize`Duration`,
        editor: $localize`Editor`,
        email: $localize`Email`,
        emailStatus: $localize`Email Status`,
        emailType: $localize`Email Type`,
        emails: $localize`Emails`,
        enterDb: $localize`Enter DB`,
        eventType: $localize`Action`,
        exceptionMessage: $localize`Details`,
        file: $localize`File`,
        fileName: $localize`Documents`,
        finalManuscriptSubmissionDate: $localize`Final Manuscript Submission Date`,
        firstCheck: $localize`First Check`,
        firstCheckState: $localize`First Check state`,
        firstName: $localize`First Name`,
        fullName: $localize`Full Name`,
        gender: $localize`Gender`,
        gridId: $localize`Grid ID`,
        groupDetails: $localize`Group Details`,
        groupId: $localize`Group ID`,
        groupName: $localize`Group Name`,
        groupStatus: $localize`Group Status`,
        groupType: $localize`Group Type`,
        groups: $localize`Groups`,
        groupsMembership: $localize`Groups Membership`,
        hasAccessToEditingTool: $localize`Has access to Editing Tool`,
        id: $localize`ID`,
        inProgress: $localize`In Progress`,
        inUse: $localize`In Use`,
        informationType: $localize`Information Type`,
        insertedDateTime: $localize`Performed Date and Time`,
        invitationStatus: $localize`Invitation status`,
        ipAccess: $localize`IP Access`,
        ipAddress: $localize`IP Address`,
        ipBlacklist: $localize`IP Blacklist`,
        isActive: $localize`Active`,
        isAdmin: $localize`Is Admin`,
        isConnected: $localize`Connection`,
        isContributor: $localize`Title Contributor`,
        isCurrent: $localize`Current`,
        isRejected: $localize`Rejected`,
        isbn: $localize`ISBN`,
        isbnEbook: $localize`ISBN eBook`,
        isbnPrint: $localize`ISBN Print`,
        isbnsFrom: $localize`ISBNs From`,
        isbnsTo: $localize`ISBNs To`,
        issnCd: $localize`ISSN CD`,
        issnPrint: $localize`ISSN Print`,
        issnWeb: $localize`ISSN Web`,
        journal: $localize`kurzel`,
        journalGroup: $localize`Journal Group`,
        journalGroupName: $localize`Journal Group Name`,
        journalGroupType: $localize`Journal Group Type`,
        journalName: $localize`Journal Name`,
        journalResponsibilities: $localize`Journal Responsibilities`,
        journalStatus: $localize`Journal Status`,
        journals: $localize`Journals`,
        keyword: $localize`Keyword`,
        keywords: $localize`Keywords`,
        kisbn: $localize`KISBN`,
        kurzel: $localize`Kurzel`,
        lastLoginDate: $localize`Last Login Date`,
        lastModified: $localize`Last modified`,
        lastName: $localize`Last Name`,
        lastFinished: $localize`Last Finished`,
        lastStarted: $localize`Last Started`,
        lastUpdated: $localize`Updated on`,
        lcCodes: $localize`LC Code/Heading`,
        lcshCodes: $localize`LCSH Code/Heading`,
        limitUploads: $localize`Limit Uploads`,
        loginCounts: $localize`Login Counts`,
        loginDate: $localize`Login Date`,
        logo: $localize`Logo`,
        materials: $localize`Materials`,
        member: $localize`Member`,
        memberDetails: $localize`Member Details`,
        memberId: $localize`Member ID`,
        memberStatus: $localize`Member Status`,
        membersCount: $localize`Members`,
        message: $localize`Message`,
        module: $localize`Module`,
        name: $localize`Name`,
        nextStart: $localize`Next Start`,
        new: $localize`New Value`,
        newValue: $localize`New Value`,
        note: $localize`Note`,
        noteType: $localize`Note Type`,
        notes: $localize`Notes`,
        old: $localize`Old Value`,
        oldValue: $localize`Old Value`,
        openAccess: $localize`Open Access`,
        orcidId: $localize`ORCID ID`,
        orgMembership: $localize`Org. Membership`,
        organization: $localize`Organization`,
        organizationDetails: $localize`Organization Details`,
        organizationId: $localize`Organization ID`,
        organizationName: $localize`Organization Name`,
        organizationStatus: $localize`Organization Status`,
        organizationType: $localize`Organization Type`,
        organizationsCount: $localize`Organizations`,
        organizer: $localize`Organizer`,
        page: $localize`Page`,
        pageFrom: $localize`Page From`,
        paper: $localize`Paper`,
        paperAssignment: $localize`Paper Assignment`,
        paperDoc: $localize`DOC`,
        paperId: $localize`Paper ID`,
        paperName: $localize`Paper Name`,
        paperPdf: $localize`PDF`,
        paperStatus: $localize`Paper Status`,
        paperTitle: $localize`Paper title`,
        papers: $localize`Papers`,
        partName: $localize`Part Name`,
        paymentModel: $localize`Payment Model`,
        pdfPageCount: $localize`Pdf Pages`,
        performDate: $localize`Perform Date`,
        performedDateTime: $localize`Used on`,
        performer: $localize`Updated by`,
        performerEmail: $localize`Performer Email`,
        performerFullName: $localize`Used by`,
        phone: $localize`Phone number`,
        phoneNumber: $localize`Phone Number`,
        pos: $localize`POS`,
        position: $localize`Position`,
        primaryContact: $localize`Primary Contact`,
        process: $localize`Process`,
        progressValue: $localize`Progress value`,
        projectName: $localize`Project Name`,
        projectsCount: $localize`Projects`,
        property: $localize`Property`,
        pwAccess: $localize`PW Access`,
        reReview: $localize`Re-review`,
        reReviewStatus: $localize`Re-Review`,
        reason: $localize`Reason`,
        reasonText: $localize`Reason`,
        reasonValue: $localize`Reason`,
        recipientNumber: $localize`Recipient Number`,
        reference: $localize`Reference`,
        referencesCheck: $localize`Reference Check`,
        relatedMembersCount: $localize`Members`,
        relatedOrganizations: $localize`Related Organizations`,
        relatedProjects: $localize`Related Projects`,
        report: $localize`Report`,
        requests: $localize`Requests`,
        responsibilities: $localize`Responsibilities`,
        responsibility: $localize`Responsibility`,
        responsibilityName: $localize`Responsibility Name`,
        result: $localize`Result`,
        returned: $localize`Returned/In Work`,
        review: $localize`Review`,
        reviewCounter: $localize`Reviews`,
        reviewStatus: $localize`Review`,
        reviewer: $localize`Reviewer`,
        reviewerNote: $localize`Reviewer's note`,
        reviewersNote: $localize`Reviewer's note`,
        role: $localize`Role`,
        roles: $localize`Roles`,
        sections: $localize`Section`,
        shortDescription: $localize`Short Description`,
        shortName: $localize`Short Name`,
        shortOrganizationDetails: $localize`Organization Details`,
        shortRead: $localize`Short Read`,
        similarityIndex: $localize`Similarity index`,
        socialNetworks: $localize`Social Networks`,
        socialNetwork: $localize`Social Network`,
        spamReports: $localize`Spam Reports`,
        startDate: $localize`Start Date`,
        state: $localize`State`,
        status: $localize`Status`,
        subject: $localize`Subject`,
        tag: $localize`Tag`,
        tags: $localize`Tags`,
        themaCategoryCode: $localize`Thema Code`,
        themaCategoryName: $localize`Thema Heading`,
        themaCodeHeading: $localize`Thema Code/Heading`,
        timerTaskType: $localize`Name`,
        titleAssignment: $localize`Title Assignment`,
        titleCategory: $localize`Title Category`,
        titleName: $localize`Title Name`,
        titlePart: $localize`Title Part`,
        titlePartName: $localize`Title Part`,
        titleStatus: $localize`Title Status`,
        titleType: $localize`Title Type`,
        titles: $localize`Titles`,
        tocRead: $localize`Toc Read`,
        tocReadCheck: $localize`ToC Read`,
        topic: $localize`Topic`,
        topics: $localize`Topics`,
        totalIsbns: $localize`Total ISBNs`,
        tripleCheck: $localize`Triple Check`,
        type: $localize`Type`,
        uniqueClicks: $localize`Unique Clicks`,
        uniqueOpens: $localize`Unique Opens`,
        universityOrOrganization: $localize`University / Organization`,
        unsubscribes: $localize`Unsubscribes`,
        uploaded: $localize`Uploaded`,
        uploadedBy: $localize`Uploaded by`,
        uploadedCount: $localize`Uploaded`,
        usageCount: $localize`Count of Usage`,
        version: $localize`Version`,
        volume: $localize`Volume`,
        volumeTitle: $localize`Volume Title`,
        website: $localize`Website`,
        websites: $localize`Website`,
        year: $localize`Year`,
        zip: $localize`Zip / Postal Code`
    };
}
