import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DividerModule } from 'primeng/divider';
import { TabMenuModule } from 'primeng/tabmenu';

import { AppRoutingModule } from 'content-manager/src/app/app-routing.module';
import { AppComponent } from 'content-manager/src/app/app.component';
import { CoreModule } from 'content-manager/src/app/core/core.module';
import { NewChapterDialogComponent } from 'content-manager/src/app/projects/project/chapters/new-chapter-dialog/new-chapter-dialog.component';
import { ProjectChaptersComponent } from 'content-manager/src/app/projects/project/chapters/project-chapters.component';
import { ProjectContentEditorsComponent } from 'content-manager/src/app/projects/project/content-editors/project-content-editors.component';
import { SearchMemberComponent } from 'content-manager/src/app/projects/project/content-editors/search-member/search-member.component';
import { CreateProjectDialogComponent } from 'content-manager/src/app/projects/project/create-project-dialog/create-project-dialog.component';
import { ProjectDetailsComponent } from 'content-manager/src/app/projects/project/details/project-details.component';
import { ProjectReturnToEditorDialogComponent } from 'content-manager/src/app/projects/project/details/return-to-editor-dialog/project-return-to-editor-dialog.component';
import { AddFileDialogComponent } from 'content-manager/src/app/projects/project/files/add-file-dialog/add-file-dialog.component';
import { ProjectFilesComponent } from 'content-manager/src/app/projects/project/files/project-files.component';
import { AuthorsRendererComponent } from 'content-manager/src/app/projects/project/papers/author/authors-renderer.component';
import { CommentsRendererComponent } from 'content-manager/src/app/projects/project/papers/comment/comments-renderer.component';
import { ProjectPapersComponent } from 'content-manager/src/app/projects/project/papers/project-papers.component';
import { ProjectPlagiarismReportCheckInfoDialogComponent } from 'content-manager/src/app/projects/project/plagiarism-reports/check-info-dialog/project-plagiarism-report-check-info-dialog.component';
import { ProjectPlagiarismReportsComponent } from 'content-manager/src/app/projects/project/plagiarism-reports/project-plagiarism-reports.component';
import { PlagiarismCheckResultRendererComponent } from 'content-manager/src/app/projects/project/plagiarism-reports/renderers/check-result/plagiarism-check-result-renderer.component';
import { ProjectHeaderComponent } from 'content-manager/src/app/projects/project/project-layout/header/project-header.component';
import { ProjectLayoutComponent } from 'content-manager/src/app/projects/project/project-layout/project-layout.component';
import { ProjectTopicsComponent } from 'content-manager/src/app/projects/project/topics/project-topics.component';
import { ProjectUpdateTopicsDialogComponent } from 'content-manager/src/app/projects/project/topics/update-topics-dialog/project-update-topics-dialog.component';
import { WelcomeBlockComponent } from 'content-manager/src/app/projects/project/welcome-block/welcome-block.component';
import { ProjectListComponent } from 'content-manager/src/app/projects/project-list.component';
import { ErrorPagesModule } from 'sc-external/shared/components/error/error-pages.module';
import { ExternalSharedModule } from 'sc-external/shared/external-shared.module';

@NgModule({
    declarations: [
        AppComponent,
        NewChapterDialogComponent,
        ProjectChaptersComponent,
        ProjectContentEditorsComponent,
        ProjectDetailsComponent,
        ProjectFilesComponent,
        ProjectHeaderComponent,
        ProjectLayoutComponent,
        ProjectPapersComponent,
        ProjectPlagiarismReportsComponent,
        ProjectTopicsComponent,
        ProjectListComponent,
        CreateProjectDialogComponent,
        AddFileDialogComponent,
        SearchMemberComponent,
        ProjectPlagiarismReportCheckInfoDialogComponent,
        PlagiarismCheckResultRendererComponent,
        AuthorsRendererComponent,
        ProjectUpdateTopicsDialogComponent,
        CommentsRendererComponent,
        WelcomeBlockComponent,
        ProjectReturnToEditorDialogComponent
    ],
    bootstrap:
        [AppComponent],
    imports:
        [BrowserModule,
            BrowserAnimationsModule,
            AppRoutingModule,
            CoreModule,
            DividerModule,
            ExternalSharedModule,
            RouterModule,
            TabMenuModule,
            FormsModule,
            ReactiveFormsModule,
            AccordionModule,
            AutoCompleteModule,
            ErrorPagesModule], providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class AppModule { }
