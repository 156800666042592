import { Inject, Injectable, InjectionToken } from '@angular/core';
import jwt_decode from 'jwt-decode';

import { intersection } from 'lodash-es';

import {
    tap,
    mergeMap,
    Observable,
    of,
    filter,
    map,
    shareReplay,
    combineLatestWith,
    Subject,
    startWith
} from 'rxjs';

import { IdentityService } from 'sc-common/core/services/identity/identity.service';
import { Permission } from 'sc-common/core/services/open-api/open-api-clients';

export const PERMISSION_TOKEN_PROVIDER = new InjectionToken<IPermissionTokenProvider>('PermissionTokenProvider');

export interface IPermissionTokenProvider {
    getPermissionsToken(): Observable<string>;
}

@Injectable()
export class PermissionService {

    public static readonly InternalViewPermissions: Permission[] = [
        Permission.AccountMembersView,
        Permission.AccountOrganizationsView,
        Permission.AccountRolesView,
        Permission.AccountSettingsView,
        Permission.AccountGroupsView,
        Permission.PublishingJournalsView,
        Permission.PublishingJournalGroupsView,
        Permission.PublishingSettingsView,
        Permission.PublishingTopicsView,
        Permission.PublishingTitleDoisView,
        Permission.PublishingTitlesView,
        Permission.PublishingIsbnsView,
        Permission.PublishingPapersView,
        Permission.TechnicalCheckManagementView,
        Permission.TechnicalCheckCheckerToolView,
        Permission.ContentManagementView,
        Permission.PromotionProjectsView,
        Permission.PromotionSettingsView,
        Permission.MailingView,
        Permission.TimerTasksView,
        Permission.BackgroundProcessesView,
        Permission.MarketingCampaignsRelatedPapersView
    ];

    private readonly _tokenSource$ = new Subject<string>();

    public readonly permissionToken$: Observable<string>;

    public readonly memberPermissions$: Observable<Permission[]>

    public readonly memberInternalViewPermissions$: Observable<Permission[]>;

    constructor(
        private readonly _identityService: IdentityService,
        @Inject(PERMISSION_TOKEN_PROVIDER) private readonly _permissionTokenProvider: IPermissionTokenProvider) {

        const isAuthenticated$ = this._identityService.isAuthenticated$
            .pipe(shareReplay({ refCount: false, bufferSize: 1 }));

        this.permissionToken$ = this._tokenSource$
            .pipe(
                startWith(null),
                combineLatestWith(isAuthenticated$),
                mergeMap(([existingToken, isAuthenticated]) =>
                    isAuthenticated
                        ? existingToken
                            ? of(existingToken)
                            : this._permissionTokenProvider.getPermissionsToken()
                        : of(null)),
                shareReplay({ refCount: false, bufferSize: 1 }));

        this.memberPermissions$ = this.permissionToken$
            .pipe(
                filter(t => !!t),
                map(t => (jwt_decode(t) as any)?.permissions),
                map(m => JSON.parse(m)));

        this.memberInternalViewPermissions$ = this.memberPermissions$
            .pipe(map(permissions => intersection(permissions, PermissionService.InternalViewPermissions)));
    }

    public requestNewToken$(): Observable<string> {

        return this._permissionTokenProvider.getPermissionsToken()
            .pipe(tap(t => this._tokenSource$.next(t)));
    }

    public hasPermission(permission: Permission): Observable<boolean> {
        return this.memberPermissions$
            .pipe(map(permissions => permissions.includes(permission)));
    }
}
