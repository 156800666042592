<p-dataView *ngIf="commentsData && commentsData.length"
            [value]="commentsData"
            layout="list"
            styleClass="mb-4"
            [paginator]="false">
    <ng-template let-commentDataItems
                 pTemplate="list">
        <div *ngFor="let commentData of commentDataItems"
             class="col-12 mt-3 pb-3">
            <sc-comment [commentData]="commentData"
                        (update)="onCommentUpdate()"
                        (delete)="onCommentDelete()"></sc-comment>
        </div>
    </ng-template>
</p-dataView>

<form class="mt-3"
      [formGroup]="form"
      (ngSubmit)="addComment()">

    <div class="flex flex-column">
        <div class="field p-fluid">
            <textarea inputId="textarea"
                      scValidatable
                      pInputTextarea
                      [autoResize]="true"
                      placeholder="Write your comments..."
                      [rows]="3"
                      formControlName="description"
                      i18n-placeholder></textarea>
        </div>
        <p-button scSubmitButton
                  [requestInProcess]="requestInProcess"
                  class="align-self-center mt-6"
                  label="Add comment"
                  i18n-label></p-button>
    </div>
</form>