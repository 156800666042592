import { Component } from '@angular/core';

import { FilterBaseComponent } from 'sc-common/shared/table/header/filters/filter-base.component';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'number-filter.component.html'
})
export class NumberFilterComponent extends FilterBaseComponent {

    constructor(tableStateService: TableStateService, tableColumn: TableColumn) {

        super(tableStateService, tableColumn);
    }

    public onNumberInput({ value }: { value: string|number; }): void {

        this.filterValue = value;
    }
}
