import { buildEnumMapProvider, ApiErrorCode } from 'sc-common';

export const apiErrorCodeProvider = buildEnumMapProvider(ApiErrorCode, getEnumText);

function getEnumText(value: ApiErrorCode): string {

    switch (value) {

        case ApiErrorCode.CaptchaValidationFailed:
            return $localize`Suspicious activity detected. Try again later.`;
    }
}

