import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Inject, Injectable, Injector } from '@angular/core';
import { ActivationEnd, ChildActivationEnd, NavigationEnd, Router } from '@angular/router';

import { DialogService } from 'primeng/dynamicdialog';

@Injectable()
export class ScDialogService extends DialogService {
    constructor(
        // eslint-disable-next-line deprecation/deprecation
        appRef: ApplicationRef,
        injector: Injector,
        router: Router,
        @Inject(DOCUMENT) document: Document) {
        super(appRef, injector, document);
        router.events.subscribe((routerEvent) => {

            if (routerEvent instanceof ChildActivationEnd ||
                routerEvent instanceof NavigationEnd ||
                routerEvent instanceof ActivationEnd) {
                this.dialogComponentRefMap?.forEach(d => d.destroy());
            }
        });
    }
}
