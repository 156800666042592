<span [ngClass]="{ 'p-float-label' : floatLabel }">
    <p-autoComplete #gridAutocomplete
                    scValidatable
                    [multiple]="false"
                    [suggestions]="filteredGridOrganizations"
                    (completeMethod)="filterGridOrganizations($event)"
                    (onSelect)="onOrganizationSelect($event.value)"
                    [minLength]="1"
                    [placeholder]="placeholder"
                    dataKey="name"
                    field="name"
                    [ngClass]="{ 'ng-invalid': control.invalid, 'ng-touched': control.touched }"
                    appendTo="body">
    </p-autoComplete>
    <label *ngIf="label"
           for="gridAutocomplete"
           [ngClass]="{'sc-required-input': required}">{{label}}</label>
</span>