import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectChaptersComponent } from 'content-manager/src/app/projects/project/chapters/project-chapters.component';
import { ProjectContentEditorsComponent } from 'content-manager/src/app/projects/project/content-editors/project-content-editors.component';
import { ProjectDetailsComponent } from 'content-manager/src/app/projects/project/details/project-details.component';
import { ProjectFilesComponent } from 'content-manager/src/app/projects/project/files/project-files.component';
import { ProjectPapersComponent } from 'content-manager/src/app/projects/project/papers/project-papers.component';
import { ProjectPlagiarismReportsComponent } from 'content-manager/src/app/projects/project/plagiarism-reports/project-plagiarism-reports.component';
import { ProjectLayoutComponent } from 'content-manager/src/app/projects/project/project-layout/project-layout.component';
import { ProjectTopicsComponent } from 'content-manager/src/app/projects/project/topics/project-topics.component';
import { ProjectListComponent } from 'content-manager/src/app/projects/project-list.component';
import { CanActivateAuthGuard, initRouteData, CanActivatePermissionGuard, Permission } from 'sc-common';
import { ForbiddenComponent } from 'sc-external/shared/components/error/forbidden/403-forbidden.component';
import { NotFoundComponent } from 'sc-external/shared/components/error/not-found/404-not-found.component';
import { ServerErrorComponent } from 'sc-external/shared/components/error/server-error/500-server-error.component';

const routes: Routes = [
    {
        path: 'forbidden',
        component: ForbiddenComponent
    },
    {
        path: 'projects',
        canActivate: [CanActivateAuthGuard, CanActivatePermissionGuard],
        data: initRouteData({ hasPermission: [Permission.ContentManagerView, Permission.ContentEditorView] }),
        children: [
            {
                path: ':id',
                component: ProjectLayoutComponent,
                children: [
                    {
                        path: 'details',
                        component: ProjectDetailsComponent
                    },
                    {
                        path: 'chapters',
                        component: ProjectChaptersComponent
                    },
                    {
                        path: 'topics',
                        canActivate: [CanActivatePermissionGuard],
                        data: initRouteData({ hasPermission: Permission.ContentManagerView }),
                        component: ProjectTopicsComponent
                    },
                    {
                        path: 'files',
                        component: ProjectFilesComponent
                    },
                    {
                        path: 'papers',
                        component: ProjectPapersComponent
                    },
                    {
                        path: 'plagiarism-reports',
                        canActivate: [CanActivatePermissionGuard],
                        data: initRouteData({ hasPermission: Permission.ContentManagerView }),
                        component: ProjectPlagiarismReportsComponent
                    },
                    {
                        path: 'content-editors',
                        canActivate: [CanActivatePermissionGuard],
                        data: initRouteData({ hasPermission: Permission.ContentManagerView }),
                        component: ProjectContentEditorsComponent
                    }
                ]
            },
            {
                path: '',
                component: ProjectListComponent
            }
        ]
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: 'server-error',
        component: ServerErrorComponent
    },
    {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'prefix'
    }];

@NgModule(
    {
        imports: [RouterModule.forRoot(routes)],
        exports: [RouterModule]
    })
export class AppRoutingModule { }
