import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { API_BASE_URL } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { plagiarismCheckStatusEnumMapProvider } from 'content-manager/src/app/core/plagiarism-check-status-enum-map';
import { projectStatusEnumMapProvider } from 'content-manager/src/app/core/project-status-enum-map';
import { environment } from 'content-manager/src/environments/environment';
import {
    CommonCoreModule,
    DefaultPermissionTokenProvider,
    ENV_TOKEN,
    HttpErrorInterceptor,
    IdentityModule,
    PERMISSION_TOKEN_PROVIDER
} from 'sc-common';

@NgModule({
    imports: [
        CommonModule,
        CommonCoreModule,
        IdentityModule.forRoot([environment.apiUrl, environment.apiCmUrl])
    ],
    exports: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: ENV_TOKEN,
            useValue: environment
        },
        {
            provide: API_BASE_URL,
            useValue: environment.apiCmUrl
        },
        {
            provide: PERMISSION_TOKEN_PROVIDER,
            useClass: DefaultPermissionTokenProvider
        },
        projectStatusEnumMapProvider,
        plagiarismCheckStatusEnumMapProvider
    ]
})
export class CoreModule { }
