import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isArray, isEmpty, isEqual, isMatchWith, isString } from 'lodash-es';

import { FileUpload } from 'primeng/fileupload';

import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ValidationConstants } from 'sc-common/core/models/generated-constants';
import { ValidatorsMessages } from 'sc-common/core/services/input-validation/validators-messages';

export declare type CustomMessage = {
    key: string;
    value: string;
};

declare type StringDictionary = { [key: string]: string; };

declare type ValidateEmailFn = (email: string) => Observable<boolean>;

declare type ValidateOrcidIdFn = (orcidId: string) => Observable<boolean>;

export class ValidationService {

    private static CustomValidatorMessageMap: StringDictionary = {};

    public static getMessage(formControl: AbstractControl, controlLabel?: string): string {

        if (!formControl || !formControl.errors) {
            return null;
        }

        if (formControl.dirty || formControl.touched) {

            const errorKeys = Object.keys(formControl.errors);
            for (const key of errorKeys) {

                let message = ValidationService.CustomValidatorMessageMap[key];

                if (!message) {

                    message = ValidationService._getPredefinedMessage(key, controlLabel, formControl.errors[key]);
                }

                return message;
            }
        }

        return null;
    }

    public static validator<T>(isValid: (value: T) => boolean, customMessage: CustomMessage): ValidatorFn {

        const customValidatorMessage: StringDictionary = {};
        customValidatorMessage[customMessage.key] = customMessage.value;
        ValidationService._registerCustomValidatorMessage(customValidatorMessage);

        return (control: AbstractControl): ValidationErrors | null => {

            if (!isValid(control.value)) {

                const validationError: ValidationErrors = {};
                validationError[customMessage.key] = { value: control.value };

                return validationError;
            } else {

                return null;
            }
        };
    }

    public static asyncValidation(
        control: AbstractControl,
        validate: (value: any) => Observable<boolean>,
        errorKey: string): Observable<ValidationErrors> | null {

        // https://stackoverflow.com/questions/36919011/how-to-add-debounce-time-to-an-async-validator-in-angular-2
        const result = timer(250).pipe(switchMap(() =>
            validate(control.value).pipe(map(response => response ? null : { [errorKey]: true }))));

        return result;
    }

    public static uniqueEmail(validateEmailFn: ValidateEmailFn): AsyncValidatorFn {
        return this._uniqueEmail(validateEmailFn);
    }

    public static uniqueEmailWithHint(validateEmailFn: ValidateEmailFn): AsyncValidatorFn {
        return this._uniqueEmail(validateEmailFn, true);
    }

    private static _uniqueEmail(validateEmailFn: ValidateEmailFn, hint: boolean = false): AsyncValidatorFn {

        const messageKey = hint
            ? ValidatorsMessages.EmailAlreadyUsedWithSearchHintKey
            : ValidatorsMessages.EmailAlreadyUsedKey;

        return (control: AbstractControl) =>
            ValidationService.asyncValidation(
                control,
                () => validateEmailFn(control.value),
                messageKey);
    }

    public static numericValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^[0-9]*$');

        if (control.value && !regex.test(control.value)) {

            return { [ValidatorsMessages.NumberKey]: { value: control.value } };
        }

        return null;
    }

    public static passwordStrengthValidator: ValidatorFn =
        control => ValidationConstants.PasswordStrengthRegex.test(control.value)
            ? null :
            { [ValidatorsMessages.PasswordStrengthKey]: true };

    public static kurzelValidator: ValidatorFn =
        control => ValidationConstants.KurzelRegex.test(control.value)
            ? null :
            { [ValidatorsMessages.KurzelKey]: true };

    public static confirmPasswordValidator(controlName: string, matchingControlName: string): ValidatorFn {

        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.get(controlName);
            const matchingControl = formGroup.get(matchingControlName);

            if (control.value && matchingControl.value && control.value !== matchingControl.value) {
                matchingControl.setErrors({ [ValidatorsMessages.ConfirmPasswordErrorKey]: true });
            }

            if (matchingControl.getError(ValidatorsMessages.ConfirmPasswordErrorKey) && control.value === matchingControl.value) {
                matchingControl.setErrors({ [ValidatorsMessages.ConfirmPasswordErrorKey]: null });
                matchingControl.updateValueAndValidity();
            }

            return null;
        };
    }

    public static addressValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^[a-zA-Z0-9.,/`\'’":\\s-\u0100-\u0100-\u017F\u0080-\u00FF\u0250-\u02AF\u0180-\u024F\u1E00-\u1EFF]+$');
        const onlyNumbers = new RegExp('^[0-9]*$');

        if (control.value && onlyNumbers.test(control.value)) {
            return { [ValidatorsMessages.NotOnlyNumbers]: { value: control.value } };
        }

        if (!control.value || regex.test(control.value)) {
            return null;
        }

        return { [ValidatorsMessages.InvalidAddress]: { value: control.value } };
    }

    public static zipVatValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^[a-zA-Z0-9.,/`\'’":\\s-\u0100-\u0100-\u017F\u0080-\u00FF\u0250-\u02AF\u0180-\u024F\u1E00-\u1EFF]+$');
        const onlyLetters = new RegExp('^[a-zA-Z.,/`\'’":\\s-\u0100-\u0100-\u017F\u0080-\u00FF\u0250-\u02AF\u0180-\u024F\u1E00-\u1EFF]+$');

        if (control.value && onlyLetters.test(control.value)) {
            return { [ValidatorsMessages.NotOnlyLetters]: { value: control.value } };
        }

        if (!control.value || regex.test(control.value)) {
            return null;
        }

        return { [ValidatorsMessages.InvalidAddress]: { value: control.value } };
    }

    public static nameValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^[a-zA-ZÀ-ʯ\'`’\\s\u0100-\u0100-\u017F\u0080-\u00FF\u0250-\u02AF\u0180-\u024F\u1E00-\u1EFF]+$');

        if (!control.value || regex.test(control.value)) {
            return null;
        }

        return { [ValidatorsMessages.InvalidName]: { value: control.value } };
    }

    public static userNameValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^[A-Za-z0-9-._@+#]+$');

        if (!control.value || regex.test(control.value)) {
            return null;
        }
        return { [ValidatorsMessages.InvalidUserName]: { value: control.value } };
    }

    public static websiteValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^https?://');

        if (!control.value) {
            return null;
        }

        let websites: string[];

        if (Array.isArray(control.value)) {
            websites = control.value;
        } else {
            websites = [control.value];
        }

        for (const website of websites) {
            if (!regex.test(website)) {
                return { [ValidatorsMessages.InvalidWebsite]: { value: website } };
            }
        }

        return null;
    }

    public static uniqueValidator(controlName: string, matchingControlName: string, matchingControls: AbstractControl[]):
        (formGroup: AbstractControl) => void {

        return (formGroup: UntypedFormGroup) => {

            const control = formGroup.get(controlName);

            if (!control || !control.value) {
                return;
            }

            const matchingValues = matchingControls.map(i => i.get(matchingControlName).value);

            let matchesCount = 0;

            matchingValues.forEach(matchingValue => {
                if (matchingValue === control.value) {
                    matchesCount++;
                }
            });

            if ((matchesCount > 1 && controlName === matchingControlName) ||
                (matchesCount > 0 && controlName !== matchingControlName)) {
                control.setErrors({ [ValidatorsMessages.UniqueValue]: true });
            }
        };
    }

    public static alphaNumSymbolValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^[A-Za-z- 0-9+: ~`<>«»%$@\\-:;"",.()*\\\'&\/?!#№=_\\[\\]{}\n\r\u0100-\u017F\u0080-\u00FF\u0250-\u02AF\u0180-\u024F\u1E00-\u1EFF\u0370-\u03FF]+$');

        if (control.value && !regex.test(control.value)) {

            return { [ValidatorsMessages.AlphaNumSymbolErrorKey]: { value: control.value } };
        }

        return null;
    }

    public static isbnValidator(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp('^97[89][0-9]{10}$');

        if (control.value) {

            const isbnWithoutDashes = control.value.replace(/-/g, '');

            if (!regex.test(isbnWithoutDashes)) {
                return { [ValidatorsMessages.InvalidIsbn]: { value: control.value } };
            }
        }

        return null;
    }

    public static orcidValidator(control: AbstractControl): ValidationErrors | null {

        const unmaskedValue = control.value?.replace(/[-_]/g, '');

        if (unmaskedValue) {

            const orcidValid = ValidationService._validateOrcidId(control.value);

            if (!orcidValid) {
                return { [ValidatorsMessages.InvalidOrcidId]: { value: control.value } };
            }
        }

        return null;
    }

    public static isFormChanged(initialValues: any, currentFormControlValues: any): boolean {

        return !isMatchWith(initialValues, currentFormControlValues, (initialValue, currentControlValue) => {

            if (isString(currentControlValue) && !currentControlValue && !initialValue) {
                return true;
            }
            else if (isArray(initialValue) && isEmpty(initialValue) && !currentControlValue) {
                return true;
            }
            else {
                return isEqual(initialValue, currentControlValue);
            }
        });
    }

    public static isbnSetValidator(isbnFromControlName: string, isbnToControlName: string): (formGroup: AbstractControl) => ValidationErrors | null {

        return (formGroup: UntypedFormGroup) => {
            const isbnFrom = formGroup.get(isbnFromControlName);
            const isbnTo = formGroup.get(isbnToControlName);

            const isbnFromValue = isbnFrom?.value;
            const isbnToValue = isbnTo?.value;

            if (isbnFromValue && isbnToValue) {
                const isbnFromNumeric = +(isbnFromValue.replace(/-/g, ''));
                const isbnToNumeric = +(isbnToValue.replace(/-/g, ''));

                if (isbnFromNumeric > isbnToNumeric) {
                    isbnTo.setErrors({ [ValidatorsMessages.InvalidIsbnTo]: true });
                }
            }

            return null;
        };
    }

    public static fromToValidator(fromControlName: string, toControlName: string, validationFromError: string, validationToError: string):
        (formGroup: AbstractControl) => ValidationErrors {

        return (formGroup: UntypedFormGroup) => {

            const fromControl = formGroup.get(fromControlName);
            const toControl = formGroup.get(toControlName);

            const from = fromControl?.value;
            const to = toControl?.value;

            if (from !== null && to !== null) {
                if (+from > +to) {
                    toControl.setErrors({ [validationToError]: true });
                    fromControl.setErrors({ [validationFromError]: true });
                }
                else {
                    if (toControl.errors !== null && toControl.errors[validationToError]) {
                        toControl.setErrors(null);
                    }
                    if (fromControl.errors !== null && fromControl.errors[validationFromError]) {
                        fromControl.setErrors(null);
                    }
                }
            }

            return null;
        };
    }

    public static fileUploadSizeValidator(hasSelectedFile: boolean, fileUpload: FileUpload): ValidationErrors | null {
        if (hasSelectedFile && !fileUpload.files?.length) {
            return { [ValidatorsMessages.InvalidFileSize]: true };
        }

        return null;
    }

    public static canFormBeSubmitted(canSubmitForm: () => boolean, customMessage: CustomMessage): ValidatorFn {

        return (): ValidationErrors | null => {
            if (!canSubmitForm()) {

                const validationError: ValidationErrors = {};
                validationError[customMessage.key] = { value: true };
                return validationError;
            } else {

                return null;
            }
        };
    }

    public static isOrcidIdUnusedValidator(validateOrcidIdFn: ValidateOrcidIdFn): AsyncValidatorFn {
        return (control: AbstractControl) =>
            ValidationService.asyncValidation(
                control,
                () => control.value
                    ? validateOrcidIdFn(control.value)
                    : of(true),
                ValidatorsMessages.OrcidIdAlreadyUsedKey
            );
    }

    public static minDateTimeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const dateControl = control.get('startDate');
            const timeControl = control.get('startTime');

            if (!dateControl || !timeControl) {
                return null;
            }

            const dateValue = dateControl.value;
            const timeValue = timeControl.value;

            if (!dateValue || !timeValue) {
                return null;
            }

            const currentDateTime = new Date();
            currentDateTime.setHours(currentDateTime.getHours() + 12);

            const selectedDateTime = new Date(
                dateValue.getFullYear(),
                dateValue.getMonth(),
                dateValue.getDate(),
                timeValue.getHours(),
                timeValue.getMinutes()
            );

            return selectedDateTime >= currentDateTime ? null : { minDateTime: ValidatorsMessages.getErrorMessage(ValidatorsMessages.InvalidStartCampaignDate, null, null) };
        };
    }

    private static _registerCustomValidatorMessage(customValidatorMessage: StringDictionary): void {

        if (customValidatorMessage) {

            ValidationService.CustomValidatorMessageMap = {
                ...ValidationService.CustomValidatorMessageMap,
                ...customValidatorMessage
            };
        }
    }

    private static _getPredefinedMessage(validatorName: string, controlLabel: string, validatorValue: any): string {

        const fieldName = controlLabel || $localize`This field`;

        return ValidatorsMessages.getErrorMessage(validatorName, fieldName, validatorValue);
    }

    private static _validateOrcidId(orcidId: string): boolean {
        if (orcidId.length != 19) {
            return false;
        }

        if (orcidId[4] !== '-' || orcidId[9] !== '-' || orcidId[14] !== '-') {
            return false;
        }

        orcidId = orcidId.replaceAll('-', '');

        const checkDigit: string = ValidationService._calculateCheckSum(orcidId.slice(0, 15));
        if (orcidId[15] !== checkDigit) {
            return false;
        }
        return true;
    }

    private static _calculateCheckSum(baseDigits: string): string {
        let total: number = 0;
        for (let i = 0; i < baseDigits.length; i++) {
            const baseDigit = baseDigits[i];
            const digit: number = parseInt(baseDigit);
            total = (total + digit) * 2;
        }
        const remainder: number = total % 11;
        const result: number = (12 - remainder) % 11;
        return result === 10 ? `X` : result.toString();
    }
}
