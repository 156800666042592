<div class="p-fluid">
    <p-autoComplete [suggestions]="members"
                    (completeMethod)="completeMethod($event)"
                    (onSelect)="onSelect($event.value)"
                    [minLength]="1"
                    [autoZIndex]="true"
                    [required]="true"
                    [unique]="true"
                    [forceSelection]="true"
                    scrollHeight="20vw"
                    appendTo="body"
                    placeholder="Enter email or full name"
                    i18n-placeholder
                    dataKey="memberId"
                    field="null"
                    [showEmptyMessage]="true"
                    [emptyMessage]="emptyMessage">
        <ng-template let-member
                     pTemplate="item">
            <div class="flex flex-row">
                <div class="flex flex-column">
                    <span class="font-bold mb-2">{{ member.firstName }} {{ member.lastName }}</span>
                    <span class="et-primary-email mb-2">{{ member.email }}</span>
                </div>
            </div>
        </ng-template>
    </p-autoComplete>
</div>