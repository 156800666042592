import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { finalize, Observable, Subject, takeUntil } from 'rxjs';

import { ApiFindTitleRequest, ApiProject, ApiProjectCreateRequest, TitlesClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { ICreateProjectDialogData } from 'content-manager/src/app/projects/project/create-project-dialog/create-project-dialog-data';
import { ApiIdNameItem, ValidationConstants, ValidationService } from 'sc-common';

@Component({
    templateUrl: './create-project-dialog.component.html'
})
export class CreateProjectDialogComponent implements OnDestroy {

    public requestInProcess: boolean;

    public readonly form: UntypedFormGroup;

    private readonly _dialogData: ICreateProjectDialogData;

    private readonly _destroy$ = new Subject<void>();

    public filteredTitles$: Observable<ApiIdNameItem[]>;

    public readonly isCreate: boolean;

    constructor(
        dialogConfig: DynamicDialogConfig,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _dialogRef: DynamicDialogRef,
        private readonly _titlesClient: TitlesClient) {

        this._dialogData = dialogConfig.data as ICreateProjectDialogData;

        this.isCreate = this._dialogData.isCreate;
        this.form = this._buildForm();

    }

    public filterTitles(event: { query: string; }): void {

        if (!this.isCreate) {
            return;
        }

        this._dialogData.excludedTitleIdArray$.pipe(takeUntil(this._destroy$)).subscribe(ids => {
            if (!event.query) {
                return;
            }

            const findRequestModel = new ApiFindTitleRequest({
                query: event.query,
                excludedTitleIdArray: ids
            });

            this.filteredTitles$ = this._titlesClient.findTitles(findRequestModel);
        });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public createUpdate(): void {
        this.isCreate ? this._create() : this._update();
    }

    private _create(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        const requestModel = new ApiProjectCreateRequest({
            name: this.form.value.name,
            titleId: this.form.value.title.id
        });

        this._dialogData.createProject(requestModel)
            .pipe(takeUntil(this._destroy$), finalize(() => this.requestInProcess = false))
            .subscribe(() => this._dialogRef.close(true));
    }

    private _update(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        const updateModel = new ApiProject({
            name: this.form.value.name
        });

        this._dialogData.updateProject(this._dialogData.model.id, updateModel)
            .pipe(takeUntil(this._destroy$), finalize(() => this.requestInProcess = false))
            .subscribe(() => this._dialogRef.close(true));
    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            name: [!this.isCreate ? this._dialogData.model.projectName : null,
            [
                Validators.required,
                ValidationService.alphaNumSymbolValidator,
                Validators.maxLength(ValidationConstants.Max200Length)
            ]],
            title: [null]
        });
    }
}
