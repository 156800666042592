import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { IdentityService } from 'sc-common/core/services/identity/identity.service';

export const CanActivateAuthGuard: CanActivateFn = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> =>
    inject(IdentityService).routeAuthCheck(state.url)

export const CanMatchAuthGuard: CanMatchFn = (): Observable<boolean> => {
    const currentRoute = inject(Router).getCurrentNavigation()?.extractedUrl?.toString().substring(1) ?? '';
    return inject(IdentityService).routeAuthCheck(currentRoute);
}
