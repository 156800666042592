import { ViewportScroller } from '@angular/common';
import {
    AfterContentInit,
    ComponentRef,
    Directive,
    Inject,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    StaticProvider,
    Type,
    ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';

import { groupBy } from 'lodash-es';

import { FilterMetadata, SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';

import { BehaviorSubject, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { ScFilter } from 'sc-common/core/models/filter';
import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { ScQueryParams } from 'sc-common/core/models/query-params';
import { ODataService } from 'sc-common/core/services/odata/odata.service';
import { ApiListItemPosition } from 'sc-common/core/services/open-api/open-api-clients';
import { TableLocalizingService } from 'sc-common/core/services/table-localizing.service';
import { WindowRefService } from 'sc-common/core/services/window-ref/window-ref.service';
import { EnumMap, enumToken } from 'sc-common/core/utils/enum-map';
import { getExpressionField, getExpressionPath } from 'sc-common/core/utils/expression-path';
import { AdvancedFilterService } from 'sc-common/shared/table/advanced-filter/advanced-filter.service';
import { ActionsRendererComponent } from 'sc-common/shared/table/body/renderers/actions/actions-renderer.component';
import { ListRendererComponent } from 'sc-common/shared/table/body/renderers/list/list-renderer.component';
import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';
import { Renderers } from 'sc-common/shared/table/body/renderers/renderers';
import { TableBodyComponent } from 'sc-common/shared/table/body/table-body.component';
import { TableCaptionComponent } from 'sc-common/shared/table/caption/table-caption.component';
import { ColGroupComponent } from 'sc-common/shared/table/colgroup/colgroup.component';
import { TableEmptyComponent } from 'sc-common/shared/table/empty/table-empty.component';
import { FilterBaseComponent } from 'sc-common/shared/table/header/filters/filter-base.component';
import { Filters } from 'sc-common/shared/table/header/filters/filters';
import { TableHeaderComponent } from 'sc-common/shared/table/header/table-header.component';
import { TableCaptionData, TABLE_CAPTION_DATA } from 'sc-common/shared/table/models/caption-data';
import { ColumnMetadataValue, getColumnMap } from 'sc-common/shared/table/models/column-map.decorator';
import { ColumnSettings } from 'sc-common/shared/table/models/column-settings';
import { DEFAULT_COLUMN_SETTINGS, IModuleColumnSettingsProvider } from 'sc-common/shared/table/models/default-column-settings-provider';
import { ITableFilterPassedParams } from 'sc-common/shared/table/models/filter-passed-params';
import { LIST_ITEM_TEMPLATE } from 'sc-common/shared/table/models/list-item-template-token';
import { PageResult } from 'sc-common/shared/table/models/page-result';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';
import { PaginatorComponent } from 'sc-common/shared/table/paginator/paginator.component';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

type HiddenColumnInfo = { order: number; index: number; field: string; };

// This directive should be applied to p-table only, in order to override default behavior
@Directive({
    selector: '[scTableSettings]',
    providers: [AdvancedFilterService, TableStateService]
})
export class TableSettingsDirective implements OnInit, OnDestroy, AfterContentInit {

    private readonly _destroy$ = new Subject<void>();

    private readonly _dynamicComponentRefs: ComponentRef<any>[] = [];

    private _canAcceptRowsOrder$ = new BehaviorSubject<boolean>(false);

    private _hasActionsColumn = false;

    private get _columns(): TableColumn[] {

        return this._table.columns as TableColumn[];
    }

    private set _columns(value: TableColumn[]) {

        this._table.columns = value;

        this._tableStateService.setColumns([...value], this._hiddenColumns.map(x => x.field));
    }

    private _hiddenColumns: HiddenColumnInfo[] = [];

    private _incomingFilter: ITableFilterPassedParams;

    private _initialRowOrderMap = new Map<any, number>();

    // @HostBinding('class.sc-table-flex-height')
    // public tableHeightStyleClass: boolean;

    @Input('scTableSettings')
    public tableSettings: TableSettings<any>;

    constructor(
        private readonly _table: Table,
        private readonly _injector: Injector,
        private readonly _viewContainerRef: ViewContainerRef,
        private readonly _odataService: ODataService,
        private readonly _advancedFilterService: AdvancedFilterService,
        private readonly _tableStateService: TableStateService,
        private readonly _tableLocalize: TableLocalizingService,
        private readonly _windowRef: WindowRefService,
        router: Router,
        @Inject(DEFAULT_COLUMN_SETTINGS) @Optional() private readonly _moduleColumnSettingsProvider: IModuleColumnSettingsProvider,
        private readonly _scroller: ViewportScroller) {

        this._incomingFilter = router.getCurrentNavigation()?.extras.state as ITableFilterPassedParams;
    }

    public ngOnInit(): void {

        if (!this.tableSettings || !this.tableSettings.dataSource || !this.tableSettings.modelType) {

            throw new Error('Custom table settings are not initialized');
        }

        // Initialization methods call order is important as long as some components depends on columns
        this._initTable();

        this._restoreTableState();

        this._initColGroup();

        this._initEmptyMessage();

        this._initDefaultSortOrder();

        this._initColumns();

        this._initDataQuery();
    }

    public ngOnDestroy(): void {

        this._dynamicComponentRefs.forEach(r => r.destroy());

        this._destroy$.next();

        this._destroy$.complete();
    }

    public ngAfterContentInit(): void {

        this._initHeader();

        this._initBody();

        this._initCaption();

        this._initPaginator();
    }

    private _restoreTableState(): void {

        if (this._table.isStateful() && !this._table.stateRestored) {

            this._table.restoreState();
        }
    }

    private _initTable(): void {

        if (this.tableSettings.useState) {
            this._table.stateKey = 'sc_' + this._tableStateService.getStateKey(this.tableSettings);
        }

        this._table.stateStorage = this.tableSettings.stateStorage;
        this._table.showLoader = true;

        this._table.paginator = this.tableSettings.paginator && !this.tableSettings.reordering;

        this._table.rows = this.tableSettings.rows;

        if (this._table.paginator) {
            this._table.showJumpToPageDropdown = false;
            this._table.showPageLinks = true;
            this._table.showCurrentPageReport = true;
            this._table.currentPageReportTemplate = $localize`Showing {first} to {last} of {totalRecords} entries`;

            this._table.rowsPerPageOptions = this.tableSettings.rowsPerPageOptions;

            this._table.alwaysShowPaginator = this.tableSettings.alwaysShowPaginator;

            this._table.onPage.pipe(takeUntil(this._destroy$)).subscribe(() => this._scroller.scrollToAnchor(this._table.id));

            this._table.rows ??= TableSettings.DefaultRowsCount;
        }

        this._table.resizableColumns = this.tableSettings.resizableColumns;
        this._table.reorderableColumns = this.tableSettings.reorderableColumns;
        this._table.selectionMode = this.tableSettings.selectionMode;
        this._table.rowHover = this.tableSettings.rowHover;
        this._table.sortMode = this.tableSettings.sortMode;

        if (this.tableSettings.grouping) {
            this._table.scrollable = false;
        } else {
            this._table.scrollable = this.tableSettings.scrollable;
        }

        this._table.columnResizeMode = this.tableSettings.columnResizeMode;
        this._table.scrollHeight = this.tableSettings.scrollHeight;

        if (this.tableSettings?.toggleColumns?.canToggleColumns) {
            this._tableStateService.columnsToggle$
                .pipe(
                    filter(x => !!x),
                    takeUntil(this._destroy$))
                .subscribe(x => this._onToggleColumns(x));
        }

        if (this._table.stateKey) {

            this._table.onStateRestore
                .pipe(takeUntil(this._destroy$))
                .subscribe((state: any) => this._onTableStateRestore(state));

            this._table.onStateSave
                .pipe(takeUntil(this._destroy$))
                .subscribe((state: any) => {

                    let stateModified = false;

                    if (this._hiddenColumns && this._hiddenColumns.length) {

                        state = { ...state, hiddenColumns: this._hiddenColumns };
                        stateModified = true;
                    }

                    if (state.expandedRowKeys) {
                        state.expandedRowKeys = null;
                        stateModified = true;
                    }

                    if (stateModified) {
                        this._table.getStorage().setItem(this._table.stateKey, JSON.stringify(state));
                    }
                });
        }

        this._tableStateService.reset$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this._onReset());

        this._tableStateService.reload$
            ?.pipe(takeUntil(this._destroy$))
            .subscribe(() => this._forceReload());

        this._tableStateService.pageSelectionChange$
            .pipe(takeUntil(this._destroy$))
            .subscribe((event) => this._table.toggleRowsWithCheckbox(event.originalEvent, event.checked));

        this._table.tableService.selectionSource$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this._tableStateService.onSelectionChanged(this._table.selection);

                if (this._table.isStateful()) {
                    this._table.saveState();
                }
            });

        this._tableStateService.filter$
            .pipe(takeUntil(this._destroy$))
            .subscribe(f => this._onFilter(f));

        this._table.onColReorder
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this._tableStateService.setColumns([...this._columns], this._hiddenColumns.map(x => x.field)));

        this.tableSettings.reload$
            ?.pipe(
                // prevent reloading table data from outside observable while table action in progress (until page reloaded from action renderer)
                withLatestFrom(this._tableStateService.actionInProgress$),
                filter(([, inProgress]) => !inProgress),
                map(([state]) => state),
                takeUntil(this._destroy$))
            .subscribe(x => this._forceReload(x?.skipLoadingIndicator));

        this._tableStateService.requestInProgress$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => this._table.loading = state);
    }

    private _initColumns(): void {

        const columnNames: string[] = this.tableSettings.columnNames;

        const keyName: string = this.tableSettings.keyFieldName;

        const needFilters: boolean = !this._table.headerTemplate && this.tableSettings.hasFilters && !this.tableSettings.reordering;

        const needRenderers = !this._table.bodyTemplate;

        if (keyName) {

            this._table.dataKey = keyName;

            this._table.rowTrackBy = (_: number, item: any) => item[keyName];
        }

        this._initHiddenColumns(columnNames);

        this._columns = columnNames
            .filter(field => !this._hiddenColumns.some(hc => hc.field === field))
            .map(modelField => this._initColumn(modelField, needFilters, needRenderers))
            .filter(c => c !== null);

        this._setDefaultFilterValues();

        if (this._table.isStateful() && this._table.reorderableColumns && !this._table.columnOrderStateRestored) {

            this._table.restoreColumnOrder();
        }
    }

    private _initHiddenColumns(fields: string[]): void {

        if (!this.tableSettings.hideColumnsAfter) {
            return;
        }

        const delimiterField = getExpressionField(this.tableSettings.hideColumnsAfter);

        const delimeterOrder = getColumnMap(this.tableSettings.modelPrototype, delimiterField).order;

        this._hiddenColumns = fields
            .map(field => ({ field, order: getColumnMap(this.tableSettings.modelPrototype, field).order }))
            .filter(x => x.order > delimeterOrder)
            .sort(x => x.order)
            .map((x, index) => ({ index: index, order: x.order - index, field: x.field }));
    }

    private _initDefaultSortOrder(): void {

        if (this._table.isStateful() && this._table.stateRestored) { return; }

        const defaultSortBy = this.tableSettings.defaultSortBy ?? {

            // If no default sort provided, then use key field as sorting field.
            field: x => x[this.tableSettings.keyFieldName]
        };

        const groupingField = this.tableSettings.groupingField;

        const reorderingField = this.tableSettings.reorderingField;

        const sortOrder: (o: string) => number = order => (!order || order === 'asc') ? 1 : -1;

        const multiSortMeta: SortMeta[] = [];

        const useGroupingSort = groupingField && !this.tableSettings.checkDefaultSortField(groupingField);

        const useReorderingSort = reorderingField && !this.tableSettings.checkDefaultSortField(reorderingField) && reorderingField !== groupingField;

        // If grouping provided then use it as first sort field.
        // If grouping field is equivalent to default sort field then skip following condition
        if (useGroupingSort) {

            multiSortMeta.push({
                field: groupingField,
                order: sortOrder(this.tableSettings.grouping.order)
            });
        }

        // If default sort provided then use it as second sorting field
        if (Array.isArray(defaultSortBy)) {

            this._table.sortMode = 'multiple';

            for (const fieldInfo of defaultSortBy) {

                multiSortMeta.push({
                    field: getExpressionField(fieldInfo.field),
                    order: sortOrder(fieldInfo.order)
                });
            }

        } else if (this.tableSettings.sortMode === 'multiple' || useGroupingSort || useReorderingSort) {

            multiSortMeta.push({
                field: getExpressionField(defaultSortBy.field),
                order: sortOrder(defaultSortBy.order)
            });

        } else {

            this._table.sortField = getExpressionField(defaultSortBy.field);
            this._table.sortOrder = sortOrder(defaultSortBy.order);
        }

        // If reordering provided then apply it as third sorting field
        if (useReorderingSort) {

            multiSortMeta.push({
                field: reorderingField,
                order: sortOrder(this.tableSettings.reordering.order)
            });
        }

        if (reorderingField) {

            this._initRowReordering();
        }

        if (multiSortMeta.length) {

            this._table.multiSortMeta = multiSortMeta;
        }
    }

    private _initRowReorderMap(items: any[]): void {
        this._canAcceptRowsOrder$.next(false);
        const reorderingField = this.tableSettings.reorderingField;
        if (reorderingField) {
            this._initialRowOrderMap.clear();
            items.forEach(rowData => this._initialRowOrderMap.set(rowData[this._table.dataKey], rowData[reorderingField]));
        }
    }

    private _initRowReordering(): void {
        const rowReorderAcceptCallback = this.tableSettings.reordering.acceptCallback;

        if (!rowReorderAcceptCallback) {
            return;
        }

        this._table.onRowReorder
            .pipe(
                map(() => this._getListItemPositions().length !== 0),
                takeUntil(this._destroy$))
            .subscribe(x => this._canAcceptRowsOrder$.next(x));

        this._tableStateService.submitRowsOrder$
            .pipe(
                tap(() => this._table.loading = true),
                switchMap(result => result
                    ? rowReorderAcceptCallback(this._getListItemPositions())
                    : of(false)),
                takeUntil(this._destroy$))
            .subscribe(() => {
                this._forceReload();
            });
    }

    private _getListItemPositions(): ApiListItemPosition[] {

        const reorderingField = this.tableSettings.reorderingField;

        const result: any[] = [];

        if (reorderingField) {

            const groupingField = this.tableSettings.groupingField;

            const rowGroups = groupingField
                ? groupBy(this._table.value, x => x[groupingField])
                : { [0]: this._table.value };

            for (const key in rowGroups) {

                const group = rowGroups[key];

                const positionList = group.map(rowData => rowData[reorderingField]).sort();

                group.map((rowData, index) => ({ id: rowData[this._table.dataKey], pos: positionList[index] }))
                    .filter(x => this._initialRowOrderMap.get(x.id) !== x.pos)
                    .forEach(x => result.push(new ApiListItemPosition(x)));
            }
        }

        return result;
    }

    private _initColumn(modelField: string, needFilters: boolean, needRenderers: boolean): TableColumn {

        const columnMetadataValue: ColumnMetadataValue = getColumnMap(this.tableSettings.modelPrototype, modelField);

        const vType = columnMetadataValue.typeRef();

        const isEnum = (typeof vType === 'object');

        const isDate = (vType === Date);

        const columnDataType: Type<any> = isEnum ? String : vType as Type<any>;

        let enumMap: EnumMap = null;

        let columnSettings: ColumnSettings = this.tableSettings.columns[modelField];

        const defaultColumnSettings = this._moduleColumnSettingsProvider?.getDefault(columnDataType);

        if (!columnSettings) {

            columnSettings = { filterExpr: x => x, displayExpr: x => x };

            if (defaultColumnSettings) {

                Object.assign(columnSettings, defaultColumnSettings);
            }

            this.tableSettings.columns[modelField] = columnSettings;

        } else if (defaultColumnSettings) {

            Object.assign(defaultColumnSettings, columnSettings);

            columnSettings = defaultColumnSettings;

            this.tableSettings.columns[modelField] = columnSettings;
        }

        if (isEnum) {

            const enumObj = vType as { [name: string]: any; };

            try {
                enumMap = this._injector.get<any>(enumToken(vType)) as EnumMap;
            }
            catch (e) {
                console.warn(e);
                throw new Error(`Enum map for field '${ modelField }' is missing`);
            };

            columnSettings.displayExpr = x => enumMap.get(enumObj[x]).label;
        }

        if (columnSettings.hidden) {
            return null;
        }

        const isColumnSortable = !columnMetadataValue.isArray
            && ((!this.tableSettings.hasDefaultSortColumnsOnly || this.tableSettings.checkDefaultSortColumn(modelField))
                && (columnSettings?.sortable ?? true));

        const sortableFieldPath = isColumnSortable
            ? (columnSettings?.sortExpr
                ? `${ modelField }${ getExpressionPath(columnSettings.sortExpr) }`
                : modelField)
            : null;

        const columnStyle: { [klass: string]: any; } = {};

        if (!this._table.stateRestored) {
            if (columnSettings.width) {

                if (typeof columnSettings.width === 'string') {
                    columnStyle.width = columnSettings.width;
                    columnStyle.maxWidth = columnSettings.width;
                } else {
                    if (columnSettings.width.min) {
                        columnStyle.minWidth = columnSettings.width.min;
                    }

                    if (columnSettings.width.max) {
                        columnStyle.maxWidth = columnSettings.width.max;
                    }
                }
            } else {
                if (isDate) {
                    columnStyle.width = '270px';
                }
                else {
                    columnStyle.minWidth = '150px';
                    columnStyle.maxWidth = '300px';
                }
            }
        }

        const tableColumn: TableColumn = {
            isKey: this._table.dataKey === modelField,
            field: modelField,
            sortable: isColumnSortable,
            header: columnSettings?.header ?? this._tableLocalize.columns[modelField] ?? modelField,
            sortableFieldPath: sortableFieldPath,
            isArray: columnMetadataValue.isArray,
            settings: columnSettings,
            enumMap,
            dataType: vType,
            isGroupable: modelField === this.tableSettings.groupingColumn,
            isReorderable: modelField === this.tableSettings.reorderingField,
            isEditable: !!columnSettings.editable,
            headerStyle: columnStyle,
            bodyStyle: { ...columnStyle }
        };

        if (needFilters) {

            this._initColumnFilter(tableColumn);
        }

        if (needRenderers) {

            this._initColumnRenderer(tableColumn);
        }

        return tableColumn;
    }

    private _initColumnFilter(tableColumn: TableColumn): void {

        const columnSettings = tableColumn.settings;

        if (columnSettings.filterable === false) {
            return;
        }

        const filterMetadata = columnSettings?.filter ?? Filters.getDefault(tableColumn);

        const filterKey = columnSettings.filterExpr
            ? `${ tableColumn.field }${ (tableColumn.isArray ? this._odataService.arrayFieldSeparator : '') }${ getExpressionPath(columnSettings.filterExpr) }`
            : tableColumn.field;

        const defaultValue = this._incomingFilter?.filterColumn === tableColumn.field
            ? this._tableStateService.getCastedFilterValue(this._incomingFilter.filterValue)
            : null;

        const vFilter = this._getFilter(filterKey);

        const restoredValue = vFilter?.value;
        const matchMode = vFilter?.matchMode as MatchMode;

        tableColumn.filterKey = filterKey;

        const initializer = (inst: FilterBaseComponent): void => {

            if (filterMetadata.initializer) {
                filterMetadata.initializer(inst);
            }

            inst.columnName = tableColumn.field;
            inst.filterKeyField = filterKey;

            if (defaultValue !== null && defaultValue !== undefined) {
                inst.filterValue = inst.appliedFilterValue = defaultValue;
            }
            else if (restoredValue !== null && restoredValue !== undefined) {
                inst.filterValue = inst.appliedFilterValue = restoredValue;
            }

            if (matchMode) {
                inst.matchMode = matchMode;
            }
        };

        const cmpInstance: FilterBaseComponent = this._createComponent(
            filterMetadata.componentType,
            initializer,
            { provide: TableColumn, useValue: tableColumn });

        tableColumn.filterTemplate = cmpInstance.templateRef;

        if (cmpInstance.filterValue !== null && cmpInstance.filterValue !== undefined) {

            tableColumn.filterValue = cmpInstance.filterValue;
            tableColumn.filterMatchMode = cmpInstance.matchMode;
        }
    }

    private _initColumnRenderer(tableColumn: TableColumn): void {

        const columnSettings = tableColumn.settings;

        const rendererMetadata = (columnSettings?.renderer ?? Renderers.getDefault(tableColumn));

        // Component initializer function
        const initializer = (inst: RendererBaseComponent): void => {

            inst.tableColumn = tableColumn;
            inst.dataExpr = columnSettings?.displayExpr ?? (x => x);

            if (rendererMetadata.initializer) {
                rendererMetadata.initializer(inst);
            }
        };

        const rendererCmpInst: RendererBaseComponent = this._createComponent(
            rendererMetadata.componentType, initializer);

        if (tableColumn.isArray && !tableColumn.settings.doNotRenderAsArray) {

            const listCmpRef: ListRendererComponent = this._createComponent(
                ListRendererComponent, null, { provide: LIST_ITEM_TEMPLATE, useValue: rendererCmpInst.displayTemplateRef });

            listCmpRef.renderAsCommaSeparatedList = columnSettings.renderAsCommaSeparatedList;

            tableColumn.bodyTemplate = listCmpRef.displayTemplateRef;
        }
        else {

            tableColumn.bodyTemplate = rendererCmpInst.displayTemplateRef;
        }

        tableColumn.isEditable &&= !!rendererCmpInst.editableTemplateRef && !rendererCmpInst.isCustomEditor;

        // If there is no display template provided use editable template as default.
        if (rendererCmpInst.editableTemplateRef && !rendererCmpInst.displayTemplateRef) {

            tableColumn.bodyTemplate = rendererCmpInst.editableTemplateRef;

        } else {

            tableColumn.bodyEditableTemplate = rendererCmpInst.editableTemplateRef;
        }
    }

    private _initCaption(): void {

        if (!this._table.captionTemplate) {

            const captionHeaderTemplate = this._table.templates?.find(x => x.getType() === 'caption-header')?.template;

            const leftButtonsTemplate = this._table.templates?.find(x => x.getType() === 'tools-panel-left')?.template;

            const rightButtonsTemplate = this._table.templates?.find(x => x.getType() === 'tools-panel-right')?.template;

            const canClear: () => boolean =
                () => Object.keys(this._table.filters)?.length
                    || this._table.selection?.length
                    || this._advancedFilterService.getFilterValue(this.tableSettings)?.filters.length;

            const captionData: TableCaptionData = {
                captionHeaderTemplate,
                rightButtonsTemplate,
                leftButtonsTemplate,
                canAcceptRowsOrder$: this._canAcceptRowsOrder$ ?? of(false),
                canClear
            };

            this._table.captionTemplate = this._createComponent(
                TableCaptionComponent,
                null,
                { provide: TABLE_CAPTION_DATA, useValue: captionData }
            ).templateRef;
        }
    }

    private _initHeader(): void {

        if (!this._table.headerTemplate) {

            this._table.headerTemplate = this._createComponent(TableHeaderComponent, (instance) => {
                instance.hasRecords = () => !!this._table.totalRecords;
                instance.hasActionsColumn = () => this._hasActionsColumn;
                instance.keyField = this._table.dataKey;
            }).templateRef;
        }
    }

    private _initBody(): void {

        if (!this._table.bodyTemplate) {

            const componentInstance = this._createComponent(TableBodyComponent);

            this._table.bodyTemplate = componentInstance.templateRef;

            const actionsTpl = this._table.templates?.find(x => x.getType() === 'row-actions')?.template;

            if (actionsTpl) {

                const actionsComponentInstance = this._createComponent(ActionsRendererComponent,
                    inst => inst.actionsTemplate = actionsTpl);

                this._hasActionsColumn = true;

                componentInstance.actionsTemplate = actionsComponentInstance.displayTemplateRef;
            }
        }
    }

    private _initPaginator(): void {

        if (!this._table.paginatorLeftTemplate) {

            const pageCmp = this._createComponent(PaginatorComponent);

            this._table.paginatorLeftTemplate = pageCmp.recordsReportTemplateRef;
        }
    }

    private _initEmptyMessage(): void {

        if (!this._table.emptyMessageTemplate) {

            this._table.emptyMessageTemplate = this._createComponent(TableEmptyComponent,
                inst => inst.hasActionsColumn = () => this._hasActionsColumn).templateRef;
        }
    }

    private _initColGroup(): void {

        if (!this._table.colGroupTemplate) {

            this._table.colGroupTemplate = this._createComponent(ColGroupComponent,
                inst => inst.hasActionsColumn = () => this._hasActionsColumn).templateRef;
        }
    }

    private _initDataQuery(): void {

        this._table.lazy = this._table.lazyLoadOnInit = true;

        this._advancedFilterService.filterApplied$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this._forceReload());

        this.tableSettings.explicitFilterSource$
            ?.pipe(
                filter(explicitFilters => !explicitFilters?.length),
                takeUntil(this._destroy$))
            .subscribe(_ =>
                setTimeout(() => {
                    this._table.value = [];
                    this._table.totalRecords = 0;
                    this._table.loading = false;
                }, 0));

        this._table.onLazyLoad.pipe(
            map(e => this._getQueryParams(e)),
            switchMap(queryParams => this.tableSettings.explicitFilterSource$
                ?.pipe(
                    filter(explicitFilters => !!explicitFilters?.length),
                    map(explicitFilters => ({ ...queryParams, filters: [...explicitFilters, ...queryParams.filters] })))
                ?? of(queryParams)),
            switchMap(queryParams =>
                this.tableSettings.dataSource(this._odataService.buildDataQuery(queryParams))
                    .pipe(map<any, [PageResult<any>, ScQueryParams]>(x => ([x, queryParams])))),
            takeUntil(this._destroy$))
            .subscribe(([pageResult, queryParams]) => {

                this._table.value = pageResult.items ?? pageResult.selectItems;
                this._table.totalRecords = pageResult.filteredCount ?? pageResult.count ?? this._table.totalRecords;
                this._table.loading = false;

                this._initRowReorderMap(this._table.value);

                this._tableStateService.pageUpdate({
                    queryParams,
                    count: pageResult.count,
                    records: pageResult.items ?? pageResult.selectItems,
                    filteredCount: pageResult.filteredCount
                });
            });

        this._forceReload();
    }

    private _forceReload(skipLoadingIndicator: boolean = false): void {

        this._table.loading = !skipLoadingIndicator;

        this._table.onLazyLoad.emit(this._table.createLazyLoadMetadata());
    }

    private _createComponent<T>(componentType: Type<T>, initializer?: (instance: T) => void, ...providers: StaticProvider[]): T {

        const cmpRef: ComponentRef<T> = this._viewContainerRef.createComponent(componentType, {
            injector: Injector.create({
                parent: this._injector,
                providers: [{ provide: TableSettings, useValue: this.tableSettings }, ...providers]
            })
        });

        if (initializer) {

            initializer(cmpRef.instance);
        }

        this._dynamicComponentRefs.push(cmpRef);

        cmpRef.changeDetectorRef.detectChanges();

        return cmpRef.instance;
    }

    private _onFilter(tableFilter: ScFilter): void {

        this._table.filter(tableFilter.value, tableFilter.field, tableFilter.matchMode);
    }

    private _onReset(): void {

        this._table.firstChange
            .pipe(take(1), takeUntil(this._destroy$))
            .subscribe(() => {
                this._initDefaultSortOrder();
                this._table.tableService.onSort(this._table.multiSortMeta?.length
                    ? this._table.multiSortMeta
                    : {
                        field: this._table.sortField,
                        order: this._table.sortOrder
                    });
            });

        this._table.selection = [];
        this._table.preventSelectionSetterPropagation = true;
        this._table.updateSelectionKeys();
        this._table.selectionChange.emit(this._table.selection);
        this._table.tableService.onSelectionChange();

        this._table.clear();

        if (this._table.isStateful()) {
            this._table.saveState();
        }
    }

    private _onToggleColumns(selectedColumns: string[]): void {

        const toRemove = this._columns.map((c: TableColumn, i: number) => ({ order: i, column: c }))
            .filter(x => !selectedColumns.includes(x.column.field) && x.column.field !== this._table.dataKey);

        const toInsert = this._hiddenColumns.filter(hc => selectedColumns.includes(hc.field));

        let needReload = false;

        toInsert.forEach(hc => {

            const column: TableColumn = this._initColumn(hc.field, true, true);

            const rangeCounter = this._hiddenColumns.filter(x => x.index > hc.index && ((x.index - hc.index) + x.order) <= hc.order).length;

            const insertAt = hc.order - rangeCounter;

            this._columns.splice(insertAt, 0, column);

            this._hiddenColumns.filter(x => x.index > hc.index)
                .forEach(x => {

                    if (((x.index - hc.index) + x.order) > hc.order) {

                        ++x.order;
                    }

                    --x.index;
                });

            const hcIndex = this._hiddenColumns.findIndex(x => x.index === hc.index);

            this._hiddenColumns.splice(hcIndex, 1);
        });

        toRemove.forEach(x => {

            const field = x.column.field;
            const filterTemplate = x.column.filterTemplate;
            const bodyTemplate = x.column.bodyTemplate;

            if (filterTemplate) {

                const filterCmpIndex = this._dynamicComponentRefs.findIndex(r => (r.instance as FilterBaseComponent)?.templateRef === filterTemplate);

                if (filterCmpIndex >= 0) {

                    this._dynamicComponentRefs[filterCmpIndex].destroy();

                    this._dynamicComponentRefs.splice(filterCmpIndex);

                    needReload = true;

                    if (this._table.filters[field]) {

                        delete this._table.filters[field];
                    }
                }
            }

            if (this._table.sortField === field) {

                this._table.sortField = null;

                needReload = true;

            } else if (this._table.multiSortMeta?.some(m => m.field === field)) {

                this._table.multiSortMeta = this._table.multiSortMeta.filter(m => m.field === field);

                needReload = true;
            }

            if (bodyTemplate) {

                const rendererCmpIndex = this._dynamicComponentRefs.findIndex(r => (r.instance as RendererBaseComponent)?.displayTemplateRef === bodyTemplate);

                if (rendererCmpIndex >= 0) {

                    this._dynamicComponentRefs[rendererCmpIndex].destroy();

                    this._dynamicComponentRefs.splice(rendererCmpIndex);
                }
            }

            this._hiddenColumns.push({ field: field, index: this._hiddenColumns.length, order: x.order });
        });

        this._columns = this._columns.filter(x => selectedColumns.includes(x.field) || x.field === this._table.dataKey);

        if (needReload) {

            this._forceReload();
        }

        this._windowRef.nativeWindow.setTimeout(() => {

            if (this._table.isStateful()) {

                this._table.saveState();
            }
        });
    }

    private _onTableStateRestore(state: any): void {

        if (this._table.restoringFilter) {

            this._table.restoringFilter = false;
        }

        for (const prop in this._table.filters) {

            if (!prop) { continue; }

            const vFilter = this._getFilter(prop);

            vFilter.value = this._tableStateService.getCastedFilterValue(vFilter.value);
        }

        if (this.tableSettings?.toggleColumns?.canToggleColumns && state?.hiddenColumns) {

            this._hiddenColumns = (state.hiddenColumns as HiddenColumnInfo[]) ?? [];
        }
    }

    private _getQueryParams(event: TableLazyLoadEvent): ScQueryParams {

        const advancedFilters = this.tableSettings.hasAdvancedFilters
            ? this._advancedFilterService.getFilterValue(this.tableSettings)
            : null;

        return {
            first: event.first,
            rows: event.rows,
            sortMeta: event.multiSortMeta ?? (event.sortField ? [{ field: event.sortField, order: event.sortOrder }] : null),
            filters: Object.entries(event.filters)
                .map(([k, v]) => {
                    const value = TableSettingsDirective._ensureFirstElement(v);
                    return {
                        field: k,
                        matchMode: value.matchMode as MatchMode,
                        value: value.value,
                        isArray: this._columns.find(c => c.filterKey === k)?.isArray
                    }
                }),
            advancedFilters
        };
    }

    private _getFilter(filterKey: string): FilterMetadata {
        const vFilter = this._table.filters[filterKey];

        if (Array.isArray(vFilter)) {
            return vFilter[0];
        }

        return vFilter;
    }

    private _setDefaultFilterValues(): void {
        this._columns
            .filter(column => column.filterValue !== null && column.filterValue !== undefined)
            .forEach(column => {
                this._table.filters[column.filterKey] = { value: column.filterValue, matchMode: column.filterMatchMode };
            });
    }

    private static _ensureFirstElement<T>(input: T | T[]): T {
        if (Array.isArray(input)) {
            if (input.length > 0) {
                return input[0];
            } else {
                throw new Error('Input was an empty array');
            }
        } else {
            return input;
        }
    }
}
